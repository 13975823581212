"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecisionResponseToJSON = exports.DecisionResponseFromJSONTyped = exports.DecisionResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DecisionResponseFromJSON(json) {
    return DecisionResponseFromJSONTyped(json, false);
}
exports.DecisionResponseFromJSON = DecisionResponseFromJSON;
function DecisionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : (0, _1.UserFromJSON)(json['user']),
        'decisions': !(0, runtime_1.exists)(json, 'decisions') ? undefined : json['decisions'],
        'explain': !(0, runtime_1.exists)(json, 'explain') ? undefined : json['explain'],
    };
}
exports.DecisionResponseFromJSONTyped = DecisionResponseFromJSONTyped;
function DecisionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': (0, _1.UserToJSON)(value.user),
        'decisions': value.decisions,
        'explain': value.explain,
    };
}
exports.DecisionResponseToJSON = DecisionResponseToJSON;
