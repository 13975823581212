"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeUndefinedAndBlocklisted = void 0;
var removeUndefinedAndBlocklisted = function (obj, blocklist) {
    if (blocklist === void 0) { blocklist = []; }
    if (Array.isArray(obj)) {
        return obj.map(function (o) { return (0, exports.removeUndefinedAndBlocklisted)(o); });
    }
    return typeof obj !== 'object'
        ? obj
        : Object.keys(obj).reduce(function (acc, key) {
            if (blocklist.includes(key) || obj[key] == undefined) {
                return acc;
            }
            return typeof obj[key] === 'object'
                ? ((acc[key] = (0, exports.removeUndefinedAndBlocklisted)(obj[key], blocklist)),
                    acc)
                : ((acc[key] = obj[key]), acc);
        }, {});
};
exports.removeUndefinedAndBlocklisted = removeUndefinedAndBlocklisted;
