"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingDataToJSON = exports.PricingDataFromJSONTyped = exports.PricingDataFromJSON = void 0;
var runtime_1 = require("../runtime");
function PricingDataFromJSON(json) {
    return PricingDataFromJSONTyped(json, false);
}
exports.PricingDataFromJSON = PricingDataFromJSON;
function PricingDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : json['price'],
        'clearPrice': !(0, runtime_1.exists)(json, 'clearPrice') ? undefined : json['clearPrice'],
        'revenue': !(0, runtime_1.exists)(json, 'revenue') ? undefined : json['revenue'],
        'rateType': !(0, runtime_1.exists)(json, 'rateType') ? undefined : json['rateType'],
        'eCPM': !(0, runtime_1.exists)(json, 'eCPM') ? undefined : json['eCPM'],
    };
}
exports.PricingDataFromJSONTyped = PricingDataFromJSONTyped;
function PricingDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'price': value.price,
        'clearPrice': value.clearPrice,
        'revenue': value.revenue,
        'rateType': value.rateType,
        'eCPM': value.eCPM,
    };
}
exports.PricingDataToJSON = PricingDataToJSON;
