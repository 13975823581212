"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentToJSON = exports.ContentFromJSONTyped = exports.ContentFromJSON = void 0;
var runtime_1 = require("../runtime");
function ContentFromJSON(json) {
    return ContentFromJSONTyped(json, false);
}
exports.ContentFromJSON = ContentFromJSON;
function ContentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'template': !(0, runtime_1.exists)(json, 'template') ? undefined : json['template'],
        'customTemplate': !(0, runtime_1.exists)(json, 'customTemplate') ? undefined : json['customTemplate'],
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
        'body': !(0, runtime_1.exists)(json, 'body') ? undefined : json['body'],
    };
}
exports.ContentFromJSONTyped = ContentFromJSONTyped;
function ContentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'template': value.template,
        'customTemplate': value.customTemplate,
        'data': value.data,
        'body': value.body,
    };
}
exports.ContentToJSON = ContentToJSON;
