"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecisionRequestToJSON = exports.DecisionRequestFromJSONTyped = exports.DecisionRequestFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DecisionRequestFromJSON(json) {
    return DecisionRequestFromJSONTyped(json, false);
}
exports.DecisionRequestFromJSON = DecisionRequestFromJSON;
function DecisionRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'placements': (json['placements'].map(_1.PlacementFromJSON)),
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : (0, _1.UserFromJSON)(json['user']),
        'keywords': !(0, runtime_1.exists)(json, 'keywords') ? undefined : json['keywords'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'referrer': !(0, runtime_1.exists)(json, 'referrer') ? undefined : json['referrer'],
        'ip': !(0, runtime_1.exists)(json, 'ip') ? undefined : json['ip'],
        'blockedCreatives': !(0, runtime_1.exists)(json, 'blockedCreatives') ? undefined : json['blockedCreatives'],
        'isMobile': !(0, runtime_1.exists)(json, 'isMobile') ? undefined : json['isMobile'],
        'includePricingData': !(0, runtime_1.exists)(json, 'includePricingData') ? undefined : json['includePricingData'],
        'notrack': !(0, runtime_1.exists)(json, 'notrack') ? undefined : json['notrack'],
        'enableBotFiltering': !(0, runtime_1.exists)(json, 'enableBotFiltering') ? undefined : json['enableBotFiltering'],
        'enableUserDBIP': !(0, runtime_1.exists)(json, 'enableUserDBIP') ? undefined : json['enableUserDBIP'],
        'consent': !(0, runtime_1.exists)(json, 'consent') ? undefined : json['consent'],
        'deviceID': !(0, runtime_1.exists)(json, 'deviceID') ? undefined : json['deviceID'],
        'parallel': !(0, runtime_1.exists)(json, 'parallel') ? undefined : json['parallel'],
        'intendedLatitude': !(0, runtime_1.exists)(json, 'intendedLatitude') ? undefined : json['intendedLatitude'],
        'intendedLongitude': !(0, runtime_1.exists)(json, 'intendedLongitude') ? undefined : json['intendedLongitude'],
        'radius': !(0, runtime_1.exists)(json, 'radius') ? undefined : json['radius'],
        'includeMatchedPoints': !(0, runtime_1.exists)(json, 'includeMatchedPoints') ? undefined : json['includeMatchedPoints'],
    };
}
exports.DecisionRequestFromJSONTyped = DecisionRequestFromJSONTyped;
function DecisionRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'placements': (value.placements.map(_1.PlacementToJSON)),
        'user': (0, _1.UserToJSON)(value.user),
        'keywords': value.keywords,
        'url': value.url,
        'referrer': value.referrer,
        'ip': value.ip,
        'blockedCreatives': value.blockedCreatives,
        'isMobile': value.isMobile,
        'includePricingData': value.includePricingData,
        'notrack': value.notrack,
        'enableBotFiltering': value.enableBotFiltering,
        'enableUserDBIP': value.enableUserDBIP,
        'consent': value.consent,
        'deviceID': value.deviceID,
        'parallel': value.parallel,
        'intendedLatitude': value.intendedLatitude,
        'intendedLongitude': value.intendedLongitude,
        'radius': value.radius,
        'includeMatchedPoints': value.includeMatchedPoints,
    };
}
exports.DecisionRequestToJSON = DecisionRequestToJSON;
