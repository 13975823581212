"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateType = exports.EventType = void 0;
var EventType;
(function (EventType) {
    EventType[EventType["ViewConversion"] = 1] = "ViewConversion";
    EventType[EventType["ClickConversion"] = 2] = "ClickConversion";
    EventType[EventType["ServerToServerConversion"] = 3] = "ServerToServerConversion";
    EventType[EventType["Upvote"] = 10] = "Upvote";
    EventType[EventType["Downvote"] = 11] = "Downvote";
    EventType[EventType["DownvoteUninteresting"] = 12] = "DownvoteUninteresting";
    EventType[EventType["DownvoteMisleading"] = 13] = "DownvoteMisleading";
    EventType[EventType["DownvoteOffensive"] = 14] = "DownvoteOffensive";
    EventType[EventType["DownvoteRepetitive"] = 15] = "DownvoteRepetitive";
    EventType[EventType["DownvoteOther"] = 16] = "DownvoteOther";
    EventType[EventType["CloseAd"] = 17] = "CloseAd";
    EventType[EventType["Like"] = 20] = "Like";
    EventType[EventType["Share"] = 21] = "Share";
    EventType[EventType["Comment"] = 22] = "Comment";
    EventType[EventType["CommentReply"] = 101] = "CommentReply";
    EventType[EventType["CommentUpvote"] = 102] = "CommentUpvote";
    EventType[EventType["CommentDownvote"] = 103] = "CommentDownvote";
    EventType[EventType["Visible"] = 30] = "Visible";
    EventType[EventType["Hover"] = 31] = "Hover";
    EventType[EventType["ExpandDiv"] = 32] = "ExpandDiv";
    EventType[EventType["ViewableImpression"] = 40] = "ViewableImpression";
    EventType[EventType["ShareOnFacebook"] = 50] = "ShareOnFacebook";
    EventType[EventType["ShareOnTwitter"] = 51] = "ShareOnTwitter";
    EventType[EventType["ShareOnPinterest"] = 52] = "ShareOnPinterest";
    EventType[EventType["ShareOnReddit"] = 53] = "ShareOnReddit";
    EventType[EventType["ShareOnEmail"] = 54] = "ShareOnEmail";
    EventType[EventType["VideoStart"] = 70] = "VideoStart";
    EventType[EventType["VideoFirstQuartile"] = 71] = "VideoFirstQuartile";
    EventType[EventType["VideoMidPoint"] = 72] = "VideoMidPoint";
    EventType[EventType["VideoThirdQuartile"] = 73] = "VideoThirdQuartile";
    EventType[EventType["VideoComplete"] = 74] = "VideoComplete";
    EventType[EventType["VideoMute"] = 75] = "VideoMute";
    EventType[EventType["VideoUnmute"] = 76] = "VideoUnmute";
    EventType[EventType["VideoPause"] = 77] = "VideoPause";
    EventType[EventType["VideoRewind"] = 78] = "VideoRewind";
    EventType[EventType["VideoResume"] = 79] = "VideoResume";
    EventType[EventType["VideoFullScreen"] = 80] = "VideoFullScreen";
    EventType[EventType["VideoExitFullScreen"] = 81] = "VideoExitFullScreen";
    EventType[EventType["VideoExpand"] = 82] = "VideoExpand";
    EventType[EventType["VideoCollapse"] = 83] = "VideoCollapse";
    EventType[EventType["VideoAcceptInvitationLinear"] = 84] = "VideoAcceptInvitationLinear";
    EventType[EventType["VideoCloseLinear"] = 85] = "VideoCloseLinear";
    EventType[EventType["VideoSkip"] = 86] = "VideoSkip";
    EventType[EventType["VideoProgress"] = 87] = "VideoProgress";
    EventType[EventType["VideoZeroSecondsViewed"] = 400] = "VideoZeroSecondsViewed";
    EventType[EventType["VideoOneSecondsViewed"] = 401] = "VideoOneSecondsViewed";
    EventType[EventType["VideoTwoSecondsViewed"] = 402] = "VideoTwoSecondsViewed";
    EventType[EventType["VideoThreeSecondsViewed"] = 403] = "VideoThreeSecondsViewed";
    EventType[EventType["VideoFourSecondsViewed"] = 404] = "VideoFourSecondsViewed";
    EventType[EventType["VideoFiveSecondsViewed"] = 405] = "VideoFiveSecondsViewed";
    EventType[EventType["VideoSixSecondsViewed"] = 406] = "VideoSixSecondsViewed";
    EventType[EventType["VideoSevenSecondsViewed"] = 407] = "VideoSevenSecondsViewed";
    EventType[EventType["VideoEightSecondsViewed"] = 408] = "VideoEightSecondsViewed";
    EventType[EventType["VideoNineSecondsViewed"] = 409] = "VideoNineSecondsViewed";
    EventType[EventType["VideoTenSecondsViewed"] = 410] = "VideoTenSecondsViewed";
    EventType[EventType["VideoFifteenSecondsViewed"] = 415] = "VideoFifteenSecondsViewed";
    EventType[EventType["VideoTwentySecondsViewed"] = 420] = "VideoTwentySecondsViewed";
    EventType[EventType["VideoTwentyFiveSecondsViewed"] = 425] = "VideoTwentyFiveSecondsViewed";
    EventType[EventType["VideoThirtySecondsViewed"] = 430] = "VideoThirtySecondsViewed";
})(EventType = exports.EventType || (exports.EventType = {}));
var RateType;
(function (RateType) {
    RateType[RateType["Flat"] = 1] = "Flat";
    RateType[RateType["CPM"] = 2] = "CPM";
    RateType[RateType["CPC"] = 3] = "CPC";
    RateType[RateType["CPAView"] = 4] = "CPAView";
    RateType[RateType["CPAClick"] = 5] = "CPAClick";
    RateType[RateType["CPAViewAndClick"] = 6] = "CPAViewAndClick";
})(RateType = exports.RateType || (exports.RateType = {}));
