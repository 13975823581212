"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecisionToJSON = exports.DecisionFromJSONTyped = exports.DecisionFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DecisionFromJSON(json) {
    return DecisionFromJSONTyped(json, false);
}
exports.DecisionFromJSON = DecisionFromJSON;
function DecisionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'adId': !(0, runtime_1.exists)(json, 'adId') ? undefined : json['adId'],
        'advertiserId': !(0, runtime_1.exists)(json, 'advertiserId') ? undefined : json['advertiserId'],
        'creativeId': !(0, runtime_1.exists)(json, 'creativeId') ? undefined : json['creativeId'],
        'flightId': !(0, runtime_1.exists)(json, 'flightId') ? undefined : json['flightId'],
        'campaignId': !(0, runtime_1.exists)(json, 'campaignId') ? undefined : json['campaignId'],
        'priorityId': !(0, runtime_1.exists)(json, 'priorityId') ? undefined : json['priorityId'],
        'clickUrl': !(0, runtime_1.exists)(json, 'clickUrl') ? undefined : json['clickUrl'],
        'contents': !(0, runtime_1.exists)(json, 'contents') ? undefined : (json['contents'].map(_1.ContentFromJSON)),
        'impressionUrl': !(0, runtime_1.exists)(json, 'impressionUrl') ? undefined : json['impressionUrl'],
        'events': !(0, runtime_1.exists)(json, 'events') ? undefined : (json['events'].map(_1.EventFromJSON)),
        'matchedPoints': !(0, runtime_1.exists)(json, 'matchedPoints') ? undefined : (json['matchedPoints'].map(_1.MatchedPointFromJSON)),
        'pricing': !(0, runtime_1.exists)(json, 'pricing') ? undefined : (0, _1.PricingDataFromJSON)(json['pricing']),
    };
}
exports.DecisionFromJSONTyped = DecisionFromJSONTyped;
function DecisionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'adId': value.adId,
        'advertiserId': value.advertiserId,
        'creativeId': value.creativeId,
        'flightId': value.flightId,
        'campaignId': value.campaignId,
        'priorityId': value.priorityId,
        'clickUrl': value.clickUrl,
        'contents': value.contents === undefined ? undefined : (value.contents.map(_1.ContentToJSON)),
        'impressionUrl': value.impressionUrl,
        'events': value.events === undefined ? undefined : (value.events.map(_1.EventToJSON)),
        'matchedPoints': value.matchedPoints === undefined ? undefined : (value.matchedPoints.map(_1.MatchedPointToJSON)),
        'pricing': (0, _1.PricingDataToJSON)(value.pricing),
    };
}
exports.DecisionToJSON = DecisionToJSON;
