"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MatchedPointToJSON = exports.MatchedPointFromJSONTyped = exports.MatchedPointFromJSON = void 0;
var runtime_1 = require("../runtime");
function MatchedPointFromJSON(json) {
    return MatchedPointFromJSONTyped(json, false);
}
exports.MatchedPointFromJSON = MatchedPointFromJSON;
function MatchedPointFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lat': !(0, runtime_1.exists)(json, 'lat') ? undefined : json['lat'],
        'lon': !(0, runtime_1.exists)(json, 'lon') ? undefined : json['lon'],
    };
}
exports.MatchedPointFromJSONTyped = MatchedPointFromJSONTyped;
function MatchedPointToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lat': value.lat,
        'lon': value.lon,
    };
}
exports.MatchedPointToJSON = MatchedPointToJSON;
