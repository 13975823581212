"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsentRequestToJSON = exports.ConsentRequestFromJSONTyped = exports.ConsentRequestFromJSON = void 0;
var runtime_1 = require("../runtime");
function ConsentRequestFromJSON(json) {
    return ConsentRequestFromJSONTyped(json, false);
}
exports.ConsentRequestFromJSON = ConsentRequestFromJSON;
function ConsentRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userKey': !(0, runtime_1.exists)(json, 'userKey') ? undefined : json['userKey'],
        'consent': !(0, runtime_1.exists)(json, 'consent') ? undefined : json['consent'],
    };
}
exports.ConsentRequestFromJSONTyped = ConsentRequestFromJSONTyped;
function ConsentRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userKey': value.userKey,
        'consent': value.consent,
    };
}
exports.ConsentRequestToJSON = ConsentRequestToJSON;
