"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventToJSON = exports.EventFromJSONTyped = exports.EventFromJSON = void 0;
var runtime_1 = require("../runtime");
function EventFromJSON(json) {
    return EventFromJSONTyped(json, false);
}
exports.EventFromJSON = EventFromJSON;
function EventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
    };
}
exports.EventFromJSONTyped = EventFromJSONTyped;
function EventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'url': value.url,
    };
}
exports.EventToJSON = EventToJSON;
