"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkipFiltersToJSON = exports.SkipFiltersFromJSONTyped = exports.SkipFiltersFromJSON = void 0;
var runtime_1 = require("../runtime");
function SkipFiltersFromJSON(json) {
    return SkipFiltersFromJSONTyped(json, false);
}
exports.SkipFiltersFromJSON = SkipFiltersFromJSON;
function SkipFiltersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'distance': !(0, runtime_1.exists)(json, 'distance') ? undefined : json['distance'],
        'facet': !(0, runtime_1.exists)(json, 'facet') ? undefined : json['facet'],
        'geodistance': !(0, runtime_1.exists)(json, 'geodistance') ? undefined : json['geodistance'],
        'geolocation': !(0, runtime_1.exists)(json, 'geolocation') ? undefined : json['geolocation'],
        'keyword': !(0, runtime_1.exists)(json, 'keyword') ? undefined : json['keyword'],
        'location': !(0, runtime_1.exists)(json, 'location') ? undefined : json['location'],
        'placementLimit': !(0, runtime_1.exists)(json, 'placementLimit') ? undefined : json['placementLimit'],
        'siteZone': !(0, runtime_1.exists)(json, 'siteZone') ? undefined : json['siteZone'],
    };
}
exports.SkipFiltersFromJSONTyped = SkipFiltersFromJSONTyped;
function SkipFiltersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'distance': value.distance,
        'facet': value.facet,
        'geodistance': value.geodistance,
        'geolocation': value.geolocation,
        'keyword': value.keyword,
        'location': value.location,
        'placementLimit': value.placementLimit,
        'siteZone': value.siteZone,
    };
}
exports.SkipFiltersToJSON = SkipFiltersToJSON;
