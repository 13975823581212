"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserToJSON = exports.UserFromJSONTyped = exports.UserFromJSON = void 0;
var runtime_1 = require("../runtime");
function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
exports.UserFromJSON = UserFromJSON;
function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !(0, runtime_1.exists)(json, 'key') ? undefined : json['key'],
    };
}
exports.UserFromJSONTyped = UserFromJSONTyped;
function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
    };
}
exports.UserToJSON = UserToJSON;
