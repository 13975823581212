"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Adzerk Decision API
 * Adzerk Decision API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlacementToJSON = exports.PlacementFromJSONTyped = exports.PlacementFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function PlacementFromJSON(json) {
    return PlacementFromJSONTyped(json, false);
}
exports.PlacementFromJSON = PlacementFromJSON;
function PlacementFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'divName': !(0, runtime_1.exists)(json, 'divName') ? undefined : json['divName'],
        'networkId': !(0, runtime_1.exists)(json, 'networkId') ? undefined : json['networkId'],
        'siteId': !(0, runtime_1.exists)(json, 'siteId') ? undefined : json['siteId'],
        'adTypes': !(0, runtime_1.exists)(json, 'adTypes') ? undefined : json['adTypes'],
        'zoneIds': !(0, runtime_1.exists)(json, 'zoneIds') ? undefined : json['zoneIds'],
        'campaignId': !(0, runtime_1.exists)(json, 'campaignId') ? undefined : json['campaignId'],
        'flightId': !(0, runtime_1.exists)(json, 'flightId') ? undefined : json['flightId'],
        'adId': !(0, runtime_1.exists)(json, 'adId') ? undefined : json['adId'],
        'clickUrl': !(0, runtime_1.exists)(json, 'clickUrl') ? undefined : json['clickUrl'],
        'properties': !(0, runtime_1.exists)(json, 'properties') ? undefined : json['properties'],
        'eventIds': !(0, runtime_1.exists)(json, 'eventIds') ? undefined : json['eventIds'],
        'overrides': !(0, runtime_1.exists)(json, 'overrides') ? undefined : json['overrides'],
        'contentKeys': !(0, runtime_1.exists)(json, 'contentKeys') ? undefined : json['contentKeys'],
        'count': !(0, runtime_1.exists)(json, 'count') ? undefined : json['count'],
        'proportionality': !(0, runtime_1.exists)(json, 'proportionality') ? undefined : json['proportionality'],
        'ecpmPartition': !(0, runtime_1.exists)(json, 'ecpmPartition') ? undefined : json['ecpmPartition'],
        'ecpmPartitions': !(0, runtime_1.exists)(json, 'ecpmPartitions') ? undefined : json['ecpmPartitions'],
        'eventMultiplier': !(0, runtime_1.exists)(json, 'eventMultiplier') ? undefined : json['eventMultiplier'],
        'skipSelection': !(0, runtime_1.exists)(json, 'skipSelection') ? undefined : json['skipSelection'],
        'adQuery': !(0, runtime_1.exists)(json, 'adQuery') ? undefined : json['adQuery'],
        'floorPrice': !(0, runtime_1.exists)(json, 'floorPrice') ? undefined : json['floorPrice'],
        'floorCpc': !(0, runtime_1.exists)(json, 'floorCpc') ? undefined : json['floorCpc'],
        'skipFilters': !(0, runtime_1.exists)(json, 'skipFilters') ? undefined : (0, _1.SkipFiltersFromJSON)(json['skipFilters']),
    };
}
exports.PlacementFromJSONTyped = PlacementFromJSONTyped;
function PlacementToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'divName': value.divName,
        'networkId': value.networkId,
        'siteId': value.siteId,
        'adTypes': value.adTypes,
        'zoneIds': value.zoneIds,
        'campaignId': value.campaignId,
        'flightId': value.flightId,
        'adId': value.adId,
        'clickUrl': value.clickUrl,
        'properties': value.properties,
        'eventIds': value.eventIds,
        'overrides': value.overrides,
        'contentKeys': value.contentKeys,
        'count': value.count,
        'proportionality': value.proportionality,
        'ecpmPartition': value.ecpmPartition,
        'ecpmPartitions': value.ecpmPartitions,
        'eventMultiplier': value.eventMultiplier,
        'skipSelection': value.skipSelection,
        'adQuery': value.adQuery,
        'floorPrice': value.floorPrice,
        'floorCpc': value.floorCpc,
        'skipFilters': (0, _1.SkipFiltersToJSON)(value.skipFilters),
    };
}
exports.PlacementToJSON = PlacementToJSON;
